import { useRouter } from "next/router";
import React, { useContext, useEffect, useState } from "react";
import Banner from "../components/common/Badge/Banner";
import { DesktopContext } from "../contexts/DesktopContextProvider/DesktopContextProvider";
import { defaultPlans } from "../components/primaryrender/billing/productUtils";
import PrimaryLink from "../components/uiwrappers/PrimaryLink/PrimaryLink";
import { isGmbPendingMoreThan20DaysAgo } from "./utils";

export function useShowBanner() {
  const {
    isGmbAuthenticated,
    gmbVerificationStatus,
    profileInfo,
    proHasChurned,
    basicInfo,
    allProsInvoices,
  } = useContext(DesktopContext);

  const [showGmbBanner, setShowGmbBanner] = useState<string>("");
  const [showFbWaitlistBanner, setShowFbWaitlistBanner] =
    useState<boolean>(false);
  const [showChurnBanner, setShowChurnBanner] = useState<boolean>(false);
  const [showLegacyPricingBanner, setShowLegacyPricingBanner] =
    useState<boolean>(false);
  const router = useRouter();

  const { current_plan } = profileInfo || {};

  function redirectToBillingPage() {
    router.push("/settings/billing");
  }

  useEffect(() => {
    if (proHasChurned) {
      setShowChurnBanner(true);
    } else {
      setShowChurnBanner(false);
    }
  }, [proHasChurned]);

  useEffect(() => {
    if (!isGmbAuthenticated) {
      setShowGmbBanner("");
      return;
    }

    if (gmbVerificationStatus === "unverified") {
      setShowGmbBanner("unverified");
    } else if (gmbVerificationStatus === "pending") {
      setShowGmbBanner("pending");
    } else if (gmbVerificationStatus === "suspended") {
      setShowGmbBanner("suspended");
    } else {
      setShowGmbBanner("");
    }
  }, [isGmbAuthenticated, gmbVerificationStatus]);

  useEffect(() => {
    if (current_plan?.type) {
      const isDefaultPlan = defaultPlans.some(
        (plan) => plan?.price === current_plan?.price
      );
      const isTestPrice = profileInfo?.is_test_price;

      if (!isDefaultPlan && !isTestPrice) {
        setShowLegacyPricingBanner(true);
      } else {
        setShowLegacyPricingBanner(false);
      }
    }
  }, [current_plan, profileInfo?.is_test_price]);

  useEffect(() => {
    const bannerShowing =
      showChurnBanner ||
      showGmbBanner != "" ||
      showLegacyPricingBanner ||
      basicInfo?.fb_tokens_expired ||
      allProsInvoices?.ads_failed_invoices?.length > 0;

    setShowFbWaitlistBanner(!bannerShowing);
  }, [
    showChurnBanner,
    showGmbBanner,
    showLegacyPricingBanner,
    basicInfo?.fb_tokens_expired,
    allProsInvoices?.ads_failed_invoices,
  ]);

  function renderFbWaitlistBanner() {
    if (showFbWaitlistBanner) {
      const header = "Interested in running Facebook Ads through Topline Pro?";
      return (
        <Banner
          icon="megaphone"
          type="custom-blue"
          bannerText={header}
          showBannerCta={true}
          exitIcon={false}
          bannerCtaText="Learn More"
          bannerCtaFunction={() =>
            window.open(
              "https://help.toplinepro.com/en/articles/9713165-facebook-ads-waitlist-is-open"
            )
          }
          closeBanner={() => setShowFbWaitlistBanner(false)}
          trailingArrow={true}
          underlineCtaText={false}
          bannerCtaTextStyle="text-sm-medium"
          linkText="Join Waitlist"
          linkUrl={
            "https://docs.google.com/forms/d/e/1FAIpQLSdcevIVacrx1qYuGF5P6oW7FaZ5n9IFPgWp3VfMM9cMoWXdYw/viewform"
          }
        />
      );
    }
  }

  function renderChurnBanner() {
    if (showChurnBanner) {
      let header = "Your plan is no longer active";
      const planType = profileInfo?.latestCanceledPlan?.type;
      if (planType) {
        const capitalizedPlanName =
          planType.charAt(0).toUpperCase() + planType.slice(1);
        header = `Your ${capitalizedPlanName} Plan is no longer active`;
      }

      return (
        <Banner
          icon="lock"
          type="yellow"
          bannerText={header}
          showBannerCta={true}
          exitIcon={false}
          bannerCtaText="Manage Plan"
          bannerCtaFunction={() =>
            router.push("/settings/billing?editSubscription=true")
          }
          closeBanner={() => setShowChurnBanner(false)}
          trailingArrow={true}
          underlineCtaText={false}
          bannerCtaTextStyle="text-sm-medium"
        />
      );
    }
  }

  function renderGmbUnverifiedBanner() {
    return (
      showGmbBanner === "unverified" &&
      !proHasChurned && (
        <Banner
          icon="location"
          bannerText="Your Google Business Profile is not verified"
          showBannerCta={true}
          exitIcon={true}
          bannerCtaText="Verify"
          bannerCtaFunction={() =>
            window.open("https://business.google.com/locations", "_blank")
          }
          closeBanner={() => setShowGmbBanner("")}
        />
      )
    );
  }

  function renderGmbPendingBanner() {
    const isGmbPendingMoreThan20Days = isGmbPendingMoreThan20DaysAgo(
      basicInfo?.gmb_pending_at
    );

    if (showGmbBanner === "pending" && !proHasChurned) {
      if (isGmbPendingMoreThan20Days) {
        return (
          isGmbPendingMoreThan20Days && (
            <Banner
              icon="clock"
              bannerText={
                <div>
                  <span className="mr-1">
                    Google is still processing your verification request. We
                    suggest
                  </span>
                  <span className="flex inline-flex">
                    <PrimaryLink
                      text="submitting a help form"
                      size="md"
                      onClickFunc={() =>
                        window.open(
                          "https://support.google.com/business/gethelp?sjid=4292097388629530677-NA",
                          "_blank"
                        )
                      }
                      id="submitting a help form"
                      customStyle="underline leading-tight"
                    />
                    <span>.</span>
                  </span>
                </div>
              }
              showBannerCta={false}
              exitIcon={true}
              closeBanner={() => setShowGmbBanner("")}
            />
          )
        );
      } else {
        return (
          <Banner
            icon="location"
            bannerText="Your Google Business Profile is pending"
            showBannerCta={false}
            exitIcon={true}
            closeBanner={() => setShowGmbBanner("")}
          />
        );
      }
    }
  }

  function renderGmbSuspendedBanner() {
    return (
      showGmbBanner === "suspended" &&
      !proHasChurned && (
        <Banner
          icon="location"
          bannerText="Your Google Business Profile is suspended"
          showBannerCta={true}
          exitIcon={true}
          bannerCtaText="Learn more"
          bannerCtaFunction={() =>
            window.open(
              "https://help.toplinepro.com/en/articles/8677617-google-suspended-your-business-profile-now-what",
              "_blank"
            )
          }
          closeBanner={() => setShowGmbBanner("")}
        />
      )
    );
  }

  function renderFbExpiredBanner() {
    return (
      basicInfo?.fb_tokens_expired && (
        <Banner
          icon="information"
          bannerText="Please reconnect your Facebook Account"
          showBannerCta={true}
          exitIcon={false}
          bannerCtaText="Reconnect →"
          bannerCtaFunction={() => router.push("/settings/connections")}
          underline={false}
        />
      )
    );
  }

  function renderDelinquentBanner() {
    return (
      <Banner
        icon="warning"
        type="yellow"
        bannerText="Attention needed"
        secondLine="We weren’t able to process your last payment."
        secondLineActionText="Update your credit card"
        secondLineActionFunction={redirectToBillingPage}
      />
    );
  }

  function renderLegacyPricingBanner() {
    if (showLegacyPricingBanner) {
      return (
        router.pathname.includes("/settings/billing") && (
          <Banner
            icon="i"
            type="custom-blue"
            bannerText={"You’re on our legacy pricing."}
            secondLine="Please contact us with any questions."
            showBannerCta={false}
            secondLineActionText="Contact Us"
            secondLineActionFunction={() =>
              window.open(
                "https://www.toplinepro.com/contact#:~:text=Talk%20to%20us%20at%20(857)%20365%2D6868.",
                "_blank"
              )
            }
          />
        )
      );
    }
  }

  function renderWebsiteDeactivatedBanner() {
    // if website is deactivated
    return (
      <Banner
        icon="warning"
        type="alert"
        bannerText="Your website is deactivated."
        secondLine="We weren’t able to process your last payment over the past 30 days."
        secondLineActionText="Reactivate"
        secondLineActionFunction={() => null}
      />
    );
  }

  function renderAdsPaymentFailureBanner() {
    if (allProsInvoices?.ads_failed_invoices?.length) {
      return (
        <Banner
          icon="warning"
          type="alert"
          bannerText="Your ads payment was unsuccessful."
          secondLine="To restart your campaign please update your payment."
          secondLineActionText="Update Payment"
          secondLineActionFunction={redirectToBillingPage}
        />
      );
    }
  }

  return {
    renderGmbUnverifiedBanner,
    renderGmbPendingBanner,
    renderGmbSuspendedBanner,
    renderFbExpiredBanner,
    renderDelinquentBanner,
    renderLegacyPricingBanner,
    renderWebsiteDeactivatedBanner,
    renderChurnBanner,
    renderAdsPaymentFailureBanner,
    renderFbWaitlistBanner,
  } as const;
}
